import type { FunctionComponent, HTMLAttributes, PropsWithChildren, ReactNode } from "react"

import { clsx } from "clsx"
import { usePathname } from "next/navigation"
import { useEffect } from "react"
import { createPortal } from "react-dom"

import { Heading } from "@jobteaser/spark/components/Heading"
import { IconButton } from "@jobteaser/spark/components/IconButton"
import { Cross } from "@jobteaser/spark/components/Icons/Cross"
import { Text } from "@jobteaser/spark/components/Text"

import { isUiShiftCompatibleWithPathname } from "@/modules/layouts/helpers/isUiShiftCompatibleWithPathname"

import styles from "./Modal.module.css"

type ModalCommonProps = PropsWithChildren<{
  footer?: ReactNode
  isClosingLocked?: boolean
  isLocked?: boolean
  subtitle?: string
  title?: string
  variant?: "enlarged" | "fullscreen"
  withOverflow?: boolean
}> &
  HTMLAttributes<HTMLDivElement>

type ModalPropsWithCloseButton = ModalCommonProps & {
  closeLabel: string
  onClose: () => void
  onOverlayClick?: () => void
  withCloseButton?: true
}

type ModalPropsWithoutCloseButton = ModalCommonProps & {
  closeLabel?: never
  onClose?: never
  onOverlayClick?: never
  withCloseButton: false
}

type ModalProps = ModalPropsWithCloseButton | ModalPropsWithoutCloseButton

function prepareModalOpening(): void {
  document.body.classList.add("app-Body--noScroll")
}

export function prepareModalClosing(): void {
  document.body.classList.remove("app-Body--noScroll")
}

export const Modal: FunctionComponent<ModalProps> = ({
  children,
  closeLabel,
  footer,
  isClosingLocked = false,
  isLocked = false,
  onClose,
  onOverlayClick,
  subtitle,
  title,
  variant,
  withCloseButton = true,
  withOverflow = false,
  ...props
}) => {
  const pathname = usePathname()
  const isUIShiftCompatible = isUiShiftCompatibleWithPathname(pathname)

  useEffect(() => {
    if (typeof window !== "undefined") {
      prepareModalOpening()
    }
  })

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      if (event.key === "Escape") {
        if (onClose && !isClosingLocked) {
          onClose()
        }
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [onClose, isClosingLocked])

  if (typeof window === "undefined") {
    return null
  }

  const handleOnClose = (): void => {
    if (!isClosingLocked) {
      prepareModalClosing()

      if (onClose) {
        onClose()
      }
    }
  }

  const handleOnOverlayClose = (): void => {
    if (!isClosingLocked) {
      prepareModalClosing()
    }

    if (onOverlayClick) {
      onOverlayClick()
    } else if (onClose) {
      onClose()
    }
  }

  return createPortal(
    <>
      <div
        className={clsx(styles.backdrop, { [styles.backdrop___fullscreen]: variant === "fullscreen" })}
        role="button"
        onKeyDown={handleOnOverlayClose}
        onClick={handleOnOverlayClose}
        tabIndex={0}
      />
      <div
        {...props}
        {...(isUIShiftCompatible && { "data-theme": "jobteaser-uishift" })}
        {...(title && { "aria-labelledby": "modal-title" })}
        className={clsx(styles.wrapper, {
          [styles.wrapper___enlarged]: variant === "enlarged",
          [styles.wrapper___fullscreen]: variant === "fullscreen",
          [styles.wrapper___withoutHeader]: !title,
          [styles.wrapper___withOverflow]: !!withOverflow,
          [styles.wrapper__isLocked]: isLocked,
        })}
        role="dialog"
      >
        {title && (
          <header className={clsx(styles.head, { [styles.head___withSubtitle]: subtitle })}>
            <div>
              <Heading variant="title2" weight="bold" as="h2" id="modal-title">
                {title}
              </Heading>
              {subtitle && (
                <Text variant="body2" className={styles.subtitle}>
                  {subtitle}
                </Text>
              )}
            </div>
            {withCloseButton && (
              <IconButton icon={Cross} onClick={handleOnClose} aria-label={closeLabel} className={styles.closeButton} />
            )}
          </header>
        )}

        {!title && withCloseButton && (
          <IconButton icon={Cross} onClick={handleOnClose} aria-label={closeLabel} className={styles.closeButton} />
        )}

        <div className={styles.content}>{children}</div>
        {footer && <footer className={styles.foot}>{footer}</footer>}
      </div>
    </>,
    document.body
  )
}
